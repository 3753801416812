.phrase {
  background: #e8ffd0;
  padding: 0.5em;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  display: table;

  &.numbered::before {
    counter-increment: phrase;
    content: counter(phrase);
    margin-right: 4px;
  }
}

.name {
  background: transparent !important;
  margin-right: 5px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.name span {
  background: transparent !important;
  padding: 0px !important;
}

.text {
  // should contain some style to be added to element
  zoom: 1;
}

.dialoguename {
  font-weight: bold;
  background: white !important;
  border-radius: 5px;
  padding: 5px;
  margin-right: 10px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.dialoguename span {
  background: white !important;
  padding: 0px !important;
}

.dialoguetext {
  // should contain some style to be added to element
  zoom: 1;
}

.container {
  position: relative;
  overflow: auto;
  counter-reset: phrase;
}

.withAudio {
  margin-left: 36px;
}

.audio {
  height: 32px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
}

.play {
  user-select: none;
  position: absolute;
  left: 0;
  margin-top: -0.3em;
  font-size: inherit;

  // highlight play/pause buttons on interactions
  &:active path {
    fill: #1890ff;
  }

  // only the circle of play button highlighted
  &:focus path:first-child {
    fill: #1890ff;
  }
}
