.block {
  display: flex;
  flex-flow: column;
  align-items: center;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  width: fit-content;
  padding: 0.5em;
}

.block:empty {
  display: none;
}

.divider {
  margin: 12px 0;
}

.button {
  margin: 0.3rem 0;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.loading {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: rgba(125,125,125,.15);
  font-size: 24px;
}

.container {
  overflow: hidden;

  &.rotated {
    width: 100%;
    position: relative;

    // margin hack to fill the empty space with given aspect ratio
    // see ImageView.render()
    .filler {
      content: "";
      display: block;
    }

    img {
      position: absolute;
      top: 0;
    }
  }
}

.withGallery {
  margin-bottom: 80px;
}

.gallery {
  position: absolute;
  bottom: -80px;
  display: flex;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 8px; // for scroll

  img {
    cursor: pointer;
    margin-right: 4px;
    border: 4px solid transparent;
    max-width: 120px;
    height: 60px;
    object-fit: cover;

    &:hover {
      border-color: #1890ff66;
    }

    &.active {
      border-color: #1890ff;
    }
  }
}
