.toolbar
  width 40px
  background #FFFFFF
  box-shadow 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.1)
  border-radius 7px
  position sticky
  top 70px

  &::before
    height 12px
    display block
    background-color rgba(#000, 0.05)
    content ""
    border-radius 7px 7px 0 0

  &__group ~ &__group
    margin-top 4px
    border-top 2px solid rgba(0, 0, 0, 0.05)

  &_expanded
    width auto
    min-width 210px
    display flex
    flex-direction column
