@import "../../assets/styles/global.scss";

.ant-list-item-action {
  margin-left: 10px !important;
}

.segment {
  position: sticky;
  top: 0;
  // hack for flex to prevent text block from glowing enormously
  min-width: 0;
  z-index: 1;
}

.main-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.editor {
  --main-bg-color: #FAFAFA;
  --sticky-items-offset: 48px;

  width: 100%;
  height: 100%;
  min-width: 320px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: column;
  background-color: var(--main-bg-color);

  @include respond("phone") {
    width: 300px;
    min-width: 300px;
  }
}

.editorfs {
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2em;
}

.menu {
  width: 320px;
  min-width: 320px;
  display: flex;
  flex-flow: column;
  z-index: 100;
  align-self: stretch;
  background-color: #fff;
  border-left: 1px solid rgba(#000, 0.1);

  @include respond("phone") {
    margin-left: 0;
    margin-top: 20px;
  }
}

.menubsp {
  display: flex;
  flex-direction: row;
  margin-top: 2em;

  & > div {
    margin-right: 2em;
    width: 33.3%;

    &:last-child {
      margin-right: 0;
    }
  }
}

.menu:empty {
  width: 0;
  min-width: auto;
}

.content {
  border: 1px solid #e8e8e8;
  padding: 10px 20px;

  @media (max-width: 640px) {
    padding: 0px;
    border: 0;
  }
}

.common {
  flex: 1;
  display: grid;
  grid-template-columns: calc(100% - 320px) 320px;
  grid-template-rows: 1fr;
  align-items: stretch;
  max-width: 100%;

  &.view-all {
    grid-template-columns: 100%;
  }
}

.commonbsp {
  display: block;
}

.pins {
  width: 100%;
  display: flex;
}

.pins div {
  text-align: right;
}

.pinsright {
  width: 320px;
  min-width: 320px;
}

@media only screen and (max-width: 768px) {
  .common {
    display: initial !important;
  }
}

/* Grid (View All mode) */

$gap: 30px;

.grid {
  flex-grow: 1;
  display: grid;
  grid-auto-columns: calc(50% - 15px);
  grid-auto-flow: column;
  grid-column-gap: $gap;
  margin: 0 30px;
  overflow: auto;
  position: relative;
}

.grid > div > h4 {
  cursor: pointer;
}

.container {
  position: relative;
  flex-grow: 1;
  min-width: 0; // famous flex hack to prevent block from growing enormously
}

.container > button {
  position: absolute;
  top: 0;
  width: $gap;
  height: 100%;

  &:hover {
    background: #eee;
  }
}

.container > button.left {
  left: 0;
}

.container > button.right {
  right: 0;
}

.grid > div:global(.hover) {
  background: #eee;
  h4 {
    color: #1890ff;
  }
}

/* don't let the empty blocks to break the nice grid */
.grid > div:empty {
  display: none;
}
