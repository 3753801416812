.section {
  .header {
    display: flex;
    justify-content: space-between;
    padding-left: 4px;
    padding-right: 4px;
    align-items: center;
  }
  .title {
    flex: 1;
  }
}

.treelabels {
  max-height: auto;
}

.treelabel :global(.ant-tree-node-content-wrapper) {
  cursor: default;
}

.treetag {
  margin: "5px";

  &:hover {
    opacity: 1;
  }
}

.item {
  color: get-color(error);
  font-size: 14px;

  &:hover {
    cursor: pointer;
    color: #f5222d;
  }
}

.lstitem {
  user-select: none;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  padding: 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px;

  & > *:not(:last-child),
  & [role="img"] {
    margin-right: 6px;
  }

  &.flat {

  }

  & button.hidden,
  & button.visible {
    height: 20px;
    display: flex;
    align-items: flex-end;
    opacity: 0.8;
    span {
      margin-right: 0;
    }
  }

  &:hover {
    background-color: var(--labelBgColor);
  }

  &.selected {
    //noinspection CssInvalidFunction
    box-shadow: 0 0 0 4px var(--labelBgColor, rgba(0,0,0,0.2));
  }

  &_label {
    justify-content: space-between;
  }

  &__actions {
    flex: 0 0 auto;

    & [role="img"] {
      margin: 0;
    }
  }

  &__actionIcon {
    flex: 0 0 auto;
    text-align: center;
    justify-content: center;
  }
}

.list .lstitem {
  border-bottom: none;
}

.uihidden {
  opacity: 0.5;
}

.uivisible {
  opacity: 1;
}

.ant-tree-switcher {
  margin-top: 15px;
}
