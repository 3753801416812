.node-minimal
  display inline-flex
  align-items center

  &__counter
    flex none
    padding-right 7px
    display flex
    color #4F00FF
    align-items center
    justify-content center

  &__icon
    margin-right 5px

    svg
      width 24px
      height 24px

