.annotation-history
  margin 0 0 8px

  &__divider
    height 1px
    position relative
    margin 14px 0
    background-color rgba(#000, 0.1)

    &::before
      top 0
      left 0
      font-size 12px
      color rgba(#000, 0.4)
      padding-right 5px
      position absolute
      content attr(title)
      transform translateY(-50%)
      background-color #fff

.history-item
  cursor default
  padding 6px 10px
  margin 0 -10px
  border-radius 5px

  &__date
    opacity 0.4

  &:not(&_disabled)
    cursor pointer

  &:hover:not(&_disabled),
  &_selected:not(&_disabled)
    background-color #efefef

  &_disabled
    opacity 0.6
    border-radius 5px
    background-color #fcfcfc

  &__userpic
    &_prediction
      color #944BFF
      background-color #E9DDF8
      box-shadow inset 0px 0px 0px 1px rgba(148, 75, 255, 0.15)
