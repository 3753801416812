@import "../../assets/styles/global.scss";

.block {
  position: relative;
  width: 100%;
  padding: 1em 1em;
  border-radius: 0.28571429rem;
  margin-bottom: 1em;

  @include respond("phone") {
    width: 100%;
    margin-right: 0;
  }
}
