.list {
  max-height: 350px;
  overflow-y: auto;
}

.item {
  display: flex;
  justify-content: space-between;
}

.selected {
  background: #f1f1f1;
}

.block {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.relationbtn {
  margin-left: 7px;
  margin-right: 7px;
}

.header {
  font-size: 12px;
  font-weight: bold;
  color: #666;
}

.section {
  width: 100%;
  display: block;
  position: relative;
  background: #fff;
  padding: 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100%;
    width: 1px;
    background: rgba(0, 0, 0, 0.05);
    content: "";
  }

  &__blocks {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

.uihidden {
  opacity: 0.5;
}

.uivisible {
  opacity: 1;
}
