.textarea-tag
  margin-bottom: 12px
  &:last-child
    margin-bottom: 0

  &__form
    margin-bottom: 12px
    &:last-child
      margin-bottom: 0
    &_hidden
      display none
      
  &__item
    position: relative
    margin-bottom 12px
    &:last-child
      margin-bottom 0
  &__input
    color #666
    background transparent
    border none !important
    box-shadow none !important
    outline none !important
    font-size: 16px
    line-height: 22px
    height: 22px
    padding: 0 24px 0 0
    margin-bottom: 0

  &__action
    position: absolute
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & > span
      margin: 0  !important
