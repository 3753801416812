.keys
  &__key
    padding 0 4px
    height 16px
    display inline-flex
    align-items center
    background #FFFFFF
    border-radius 2px
    font-weight bold
    font-size 12px
    text-transform uppercase
    box-shadow 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 1px 0px rgba(0, 0, 0, 0.08)

  &__key + &__key
    margin-left 4px

  &__key-group
    margin-left -8px
    margin-right -8px
    padding-left 8px
    padding-right 8px

  &__key-group + &__key-group
    margin-top 5px
    border-top 2px solid rgba(#000, 0.05)
