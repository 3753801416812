.ground-truth
  &__toggle
    padding 0

  &__indicator
    width 16px
    height 16px
    color rgba(#000, 0.4)

    &_active
      color #ffbb1a

      path
        fill-opacity 1
        stroke-opacity 1
