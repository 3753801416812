.ls-menu {
  .ant-tree {
    padding: 16px 0;
  }

  .ant-tree-node-content-wrapper {
    // official flex sizing fix; here is for text-overflow in the tree nodes
    min-width: 0;
  }

  .ant-tree .ant-tree-list-holder-inner {
    padding: 0 16px;
  }

  .ant-tree .ant-tree-treenode {
    position: relative;
    padding: 0;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }

  .ant-tree .ant-tree-node-content-wrapper {
    padding: 0;
    margin-bottom: 12px;
    transition: margin-bottom 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .ant-tree .ant-tree-node-content-wrapper:hover {
    background: none;
  }

  .ant-tree .ant-tree-switcher {
    width: 24px;
    height: 32px;
    text-align: center;
    z-index: 100;
    display: flex;
    flex-flow: column;
    justify-content: center;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

    &.ant-tree-switcher_close {
      transform: scaleY(-1);
    }
  }

  .ant-tree .ant-tree-indent-unit {
    width: 16px;
  }

  .ant-tree .ant-tree-indent:empty {
    & + .ant-tree-switcher {
      margin-left: -12px;
    }

    & + .ant-tree-switcher.ant-tree-switcher-noop {
      margin-left: 0;
      display: none;
    }
  }
}
