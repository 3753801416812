.controls
  flex 1
  height 100%
  display grid
  grid-auto-columns 1fr
  grid-auto-flow column
  justify-content flex-end

  &__tooltip-wrapper
    button
      width 100%

  &__skipped-info
    display flex
    svg
      margin 0 8px 0 4px

  .button
    height 100%
    font-size 16px
    min-width 160px
    box-shadow none
    border-radius 0
