.main-view
  flex 1
  z-index 1
  display flex
  position relative
  flex-direction column
  justify-content space-between

  &__annotation
    padding 1em

  &__infobar
    padding 6px 1em 7px
    font-size 14px
    color rgba(#000, 0.6)
    background-color var(--main-bg-color)
    border-top 1px solid rgba(#000, 0.1)
    position sticky
    bottom 0
