.topbar
  top 0
  height 48px
  width 100%
  z-index 101
  flex none
  display flex
  position sticky
  align-items stretch
  justify-content space-between
  background-color #fff
  border-bottom 1px solid rgba(#000, 0.1)
  user-select none

  &__group
    display flex
    align-items stretch

  &__section
    display flex
    padding 0 8px
    align-items center
    box-sizing border-box

    &_flat
      padding 0

    & + &
      border-left 1px solid rgba(#000, 0.1)
