.block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 1em;
}

.button {
  margin-bottom: 10px;
  margin-right: 10px;
}

.labels {
  word-break: break-word;
}

.tag {
  margin-bottom: 5px;
  white-space: normal !important;
}

.statesblk > span {
  display: block;
}

.statesblk > div {
  margin-bottom: 0;
}

.row {
  display: flex;
  white-space: pre-wrap;
  margin-bottom: 12px;
}

.long {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
